<template>
  <div class="my-3">
    <h1>Login two factor</h1>
    <b-form ref="form" @submit.stop.prevent="handleSubmit" @reset="onReset">
      <b-row>
        <b-col lg="4">
          <b-alert v-model="showLoginFailure" variant="danger" fade dismissible>
            Der var fejl i brugernavn eller adgangskode
          </b-alert>
          <b-form-group
            id="input-group-1"
            label="Email adresse:"
            label-for="input-1"
            description="Indtast din METZ email"
            invalid-feedback="Email skal udfyldes"
          >
            <b-form-input
              id="input-1"
              v-model="form.username"
              type="email"
              placeholder="Indtast din METZ email"
              :state="emailState"
              required
            ></b-form-input>
          </b-form-group>

          <b-form-group
            id="input-group-2"
            label="Din adgangskode:"
            label-for="input-2"
            description="Hvis du glemmer din adgangskode, kan du sende en email til it@metz.dk med en ny adgangskode. Du vil så få besked når den er opdateret"
            invalid-feedback="Adgangskode skal udfyldes"
          >
            <b-form-input
              type="password"
              id="input-2"
              v-model="form.password"
              placeholder="Adgangskode ord"
              :state="passwordState"
              required
            ></b-form-input>
          </b-form-group>

          <b-form-group
            id="input-group-2"
            label="Domain url:"
            label-for="input-2"
            description="Produktion = https://api.metz.dk Test = https://api-dev.metz.dk"
            invalid-feedback="Ret domain "
          >
            <b-form-input
              type="text"
              id="input-3"
              v-model="form.domainUrl"
              placeholder="Domain url"
            ></b-form-input>
          </b-form-group>
        </b-col>
      </b-row>
      <b-row>
        <b-col lg="2"></b-col>
        <b-col>
          <b-button id="login" type="submit" class="my-3" variant="success"
            >Login</b-button
          >
        </b-col>
      </b-row>
    </b-form>
  </div>
</template>

<script>
import apis from "../../apis/Apis.js";
import cookies from "js-cookie";
import constants from "../../constants.js"

export default {
  data() {
    return {
      showLoginFailure: false,
      shopType: constants.shopType,
      emailState: null,
      passwordState: null,
      form: {
        username: "",
        password: "",
        domainUrl: this.$store.getters.getDefaultDomainUrl,
      },
    };
  },

  methods: {
    handleSubmit() {
      //   Exit when the form isn't valid
      if (!this.checkFormValidity()) {
        return;
      }
      this.login();
    },

    checkFormValidity() {
      const valid = this.$refs.form.checkValidity();
      this.emailState = valid;
      this.passwordState = valid;
      return valid;
    },

    login: async function () {
      this.isLoading = true;

      try {
        const response = await apis.login(
          this.form.username,
          this.form.password,
          this.shopType
        );

        this.handleRespose(response);

        this.isLoading = false;
      } catch (error) {
        console.log(error);
      }
    },

    handleRespose(res) {
      console.log("handleRespose ", res.data);
      if (res.data.errorCode > 0) {
        this.showLoginFailure = true;
        console.log("Fejl i login");
      } else {
        cookies.set(res.data.cookieName, res.data.cookieId);
        this.$store.commit("setFullname", res.data.fullname);
        this.$store.commit("setUsername", res.data.username);
        this.$store.commit("setInitials", res.data.initials);
        localStorage.fullname = res.data.fullname;
        localStorage.username = res.data.username;
        localStorage.initials = res.data.initials;

        this.$store.commit("isUserAuthenticated", true);
        //Redirect to twofactor
        
        this.$router.push("PincodeAuth");
      }
    },

    onReset() {
      this.form.email = "";
      this.form.name = "";
    },
  },
};
</script>

<style scoped>
</style>