<template>
  <div class="">
    <user-login></user-login>
  </div>
</template>

<script>
// @ is an alias to /src
import UserLogin from '../components/user/UserLogin_two_factor.vue'

export default {
  name: 'Register',
  components: {
  UserLogin
  }
}
</script>